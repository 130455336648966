// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Change the hex values below to alter the main color scheme.

$primary:       #060606;  //009b72
$primary-2:     #2E241D;
$primary-3:     #96240E;

// $primary: #163460;
// $primary-2: #0074ff;
// $primary-3: #00A9FE;

$white: #fdfdff;
$black: #060606;

@import url("//hello.myfonts.net/count/3b7b87");

  @font-face {
    font-family: 'Code-Pro-Black';
    src: url('../../fonts/Code-Pro-Black/font.woff2') format('woff2'), url('../../fonts/Code-Pro-Black/font.woff') format('woff');
  }
  @font-face {
    font-family: 'Code-Pro-Light-LC';
    src: url('../../fonts/Code-Pro-Light-LC/font.woff2') format('woff2'), url('../../fonts/Code-Pro-Light-LC/font.woff') format('woff');
  }
  @font-face {
    font-family: 'Code-Pro-Black-LC';
    src: url('../../fonts/Code-Pro-Black-LC/font.woff2') format('woff2'), url('../../fonts/Code-Pro-Black-LC/font.woff') format('woff');
  }
  @font-face {
    font-family: 'Code-Pro-Bold-LC';
    src: url('../../fonts/Code-Pro-Bold-LC/font.woff2') format('woff2'), url('../../fonts/Code-Pro-Bold-LC/font.woff') format('woff');
  }
  @font-face {
    font-family: 'Code-Pro';
    src: url('../../fonts/Code-Pro/font.woff2') format('woff2'), url('../../fonts/Code-Pro/font.woff') format('woff');
  }
  @font-face {
    font-family: 'Code-Pro-LC';
    src: url('../../fonts/Code-Pro-LC/font.woff2') format('woff2'), url('../../fonts/Code-Pro-LC/font.woff') format('woff');
  }
  @font-face {
    font-family: 'Code-Pro-Light';
    src: url('../../fonts/Code-Pro-Light/font.woff2') format('woff2'), url('../../fonts/Code-Pro-Light/font.woff') format('woff');
  }
  @font-face {
    font-family: 'Code-Pro-Bold';
    src: url('../../fonts/Code-Pro-Bold/font.woff2') format('woff2'), url('../../fonts/Code-Pro-Bold/font.woff') format('woff');
  }

$font-family-sans-serif:      "Code-Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);